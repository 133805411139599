
@media screen and (min-width: 760px) {
   .hidebar {display: none!important; }
  } 

@media screen and (max-width: 760px) {
  .MenuIcon {display: none!important; }
   .sidebar-enable { display: block; }
} 

/* @media screen and (max-width: 600px) {
    .hidebar {display: block!important; }
   }  */
 
.Login_error {text-align: center; background: #1f4055; padding: 17px; border-radius: 5px; } 
.hidebar {display: none; } 
.hidetxt{display :none; width: 70px!important; text-overflow: ellipsis; } 
.logo-box-width {width: 70px!important; margin-left: 0px!important; } 
.sidebar {width: 40px; } 
.content-margin {margin-left:  70px!important; } ul li {margin-top: "0px"; } 
/* team Setting Screen  */ .admin-name  {color: "#fff"; padding: "12px"; } 
.Atr_div :hover {background: "red"} 
.navigation {width: 300px; } 
/* reset our lists to remove bullet points and padding */ 
.mainmenu, .submenu {list-style: none; padding: 0; margin: 0; } 
/* make ALL links (main and submenu) have padding and background color */ 
.mainmenu a, .mainmenu label {display: block; text-decoration: none; font-size: 15px!important; /*margin-bottom: 15px;*/  /* margin-top: 17px; color: #fff; */} 
.mainmenu input {display: none; } 
/* add hover behaviour */ 
/* 
.mainmenu span:hover {color: #777; } 
.mainmenu div:hover {color: #777; } 
*/ 
/* when hovering over a .mainmenu item, display the submenu inside it. we're changing the submenu's max-height from 0 to 200px; */ 
.mainmenu :checked+.submenu {display: block; max-height: 100%; } 
/* we now overwrite the background-color for .submenu links only. CSS reads down the page, so code at the bottom will overwrite the code at the top. */ 
/* 
.submenu a {background-color: #ddd; } 
*/ 
/* hover behaviour for links inside .submenu */ 
  .submenu a:hover {color: #999; } 
  /* this is the initial state of all submenus. we set it to max-height: 0, and hide the overflowed content. */ 
  .submenu {overflow: hidden; max-height: 0; /* -webkit-transition: all 0.5s ease-out; */ } 
  .Menuedit{background-color:transparent; /* background-color: #4CAF50; border: none; margin-right: 400px; */ border: 1px solid #376295; color: #fff; padding: 5px 15px; font-size: 10px; cursor: pointer; border-radius: 5px; } 
.default:hover {background: #376295; } 

.cursor_pointer  {cursor: pointer; } 
.modal-dialog {position: absolute; top: 50%; left: 50%; transform: translate(-50%, calc(-50% - .5px)) !important; } 
/* Administration item */ 
.hide_item_container {display: none; } 
.item_link {color: #777; } 
/* .teamActive {color: #fff; } */ 
.Active {color: #777; } 
.cursor-pointer {cursor: pointer; } 
.showTeam {display: block } 
.hideTeam {display: none; } 
.select {background-color: #fff !important;color: #212529 !important; } 
input[type=”text”]:disabled {background-color: rgb(204, 80, 80); opacity: 1; }

.ck-editor__editable {
  min-height: 258px;
}
.min_ck .ck-editor__editable {
  min-height: 70px;
}